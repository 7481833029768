<div class="container-fluid-padding">
  <p-heading class="title-font-x-large">{{
    'ownership_status_update_success.headline.Thank_you_for_updating_your_details'
      | transloco
  }}</p-heading>
  <div class="spacing-fluid-medium"></div>
  <p-text>{{
    'ownership_status_update_success.text.We_have_successfully_received_your_information_and_will_remove_this_vehicle_from_your_account'
      | transloco
  }}</p-text>
</div>
