<div class="container-fluid-padding">
  <p-heading class="title-font-x-large">{{
    'ownership_status_update.headline.You_do_not_own_this_Porsche_anymore?'
      | transloco
  }}</p-heading>
  <div class="spacing-fluid-medium"></div>
  <p-text size="medium" weight="semi-bold">{{
    'ownership_status_update.subheadline.Ownership_Status_Update' | transloco
  }}</p-text>
  <div class="spacing-fluid-x-small"></div>
  <p-text>{{
    'ownership_status_update.text.Please_remove_the_following_vehicle_from_my_account'
      | transloco
  }}</p-text>
  <div class="spacing-fluid-small"></div>
  <div class="image__container">
    <img
      class="image__container--size"
      src="../../../assets/images/fallback-vehicle.png"
      no-referrer
    />
    <p-text class="image__container--text" weight="semi-bold">{{
      model$ | async
    }}</p-text>
    <p-text class="image__container--text" *ngIf="vin$ | async as vin"
      >VIN: {{ vin }}</p-text
    >
  </div>
  <div class="spacing-fluid-x-small"></div>
  <p-text-field-wrapper
    [label]="
      'ownership_status_update.label_datepicker.I_no_longer_own_this_vehicle_since'
        | transloco
    "
    [description]="
      'ownership_status_update.description_text_datepicker.Please_select_an_approximate_date'
        | transloco
    "
    [state]="isInvalid() ? 'error' : 'none'"
    [message]="'ownership_status_update.message.error_date' | transloco"
  >
    <input type="text" class="hidden-input" />
    <app-date-picker
      class="date-picker-style"
      [locale]="locale"
      [placeholderTopLabel]="false"
      [placeholder]="''"
      [disableInput]="false"
      [autoPositioning]="false"
      (changeDate)="terminationDate($event)"
      (touched)="onFocus()"
      [isInvalid]="isInvalid()"
    >
    </app-date-picker>
  </p-text-field-wrapper>
  <div class="spacing-fluid-medium"></div>
  <p-button (click)="terminateOwnership()">{{
    'ownership_status_update.button.submit' | transloco
  }}</p-button>
</div>
