import { inject, Injectable, NgModule } from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule
} from '@ngneat/transloco';
import { environment } from '../environments/environment';
import { Locale, validLocales } from './models/locale.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PhraseHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  private getTranslationsKey = (lang: string) => `phrase-cache-${lang}`;
  private getLastModifiedKey = (lang: string) =>
    `phrase-cache-last-mod-${lang}`;

  getTranslation(lang: Locale) {
    let httpHeaders = new HttpHeaders();
    const lastModified = localStorage.getItem(this.getLastModifiedKey(lang));
    if (lastModified) {
      httpHeaders = httpHeaders.set('If-Modified-Since', lastModified);
    }
    return this.http
      .get(
        `https://api.phrase.com/v2/projects/4849663cdd85df2e2ab5e798e5f60829/locales/${lang}/download`,
        {
          params: {
            access_token: environment.phraseToken,
            file_format: 'nested_json',
            fallback_locale_id: '5148e95a839270fdd68e99495dfc3225',
            include_empty_translations: true
          },
          observe: 'response',
          headers: httpHeaders
        }
      )
      .pipe(
        tap(response => {
          localStorage.setItem(
            this.getLastModifiedKey(lang),
            response.headers.get('last-modified') || ''
          );
          localStorage.setItem(
            this.getTranslationsKey(lang),
            JSON.stringify(response)
          );
        }),
        catchError(err => {
          const storedTranslations = localStorage.getItem(
            this.getTranslationsKey(lang)
          );
          return of(
            err.status === 304 && storedTranslations
              ? JSON.parse(storedTranslations)
              : {}
          );
        }),
        map((response: any) => response.body)
      );
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: validLocales,
        defaultLang: Locale.EN,
        fallbackLang: Locale.EN
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: PhraseHttpLoader }
  ]
})
export class TranslocoRootModule {}
