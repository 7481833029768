import { Pipe, PipeTransform } from '@angular/core';
import { RangePeriod } from '../model/range-period.interface';

@Pipe({
  name: 'containsDate'
})
export class ContainsDatePipe implements PipeTransform {
  /* This function takes an interval with nullable start/end dates
       and a variable number of date parts (at least 1 and at most 7:
       year, month, day, hour, minute, second, millisecond).
       There are two reasons why it doesn't simply accept a date as the
       second parameter:
         - This pipe is used inside a ngFor for days, months and years
           in the calendar. The context inside those loops is limited,
           there is no access to a date instance for each year for example.
         - The number of parts itself is useful to determine the date unit
           against which the interval should be checked. For example, if
           there is only one part with the year, then the dates
           in the interval will be truncated to only the year during the
           comparison. So if the interval is [2015-06-20, 2015-12-25], this
           pipe will return that the interval contains the year 2015.
    */
  transform(interval: RangePeriod, ...parts: number[]): unknown {
    const { startDate, endDate } = interval;

    if (!startDate && !endDate) {
      return true;
    }

    const lowerBound = startDate
      ? this.truncateDate(startDate, parts.length)
      : Number.MIN_SAFE_INTEGER;
    const upperBound = endDate
      ? this.truncateDate(endDate, parts.length)
      : Number.MAX_SAFE_INTEGER;
    const timestamp = this.getTimestampFromParts(parts);

    return timestamp >= lowerBound && timestamp <= upperBound;
  }

  private getTimestampFromParts(parts: number[]) {
    return new Date(
      parts[0], // there will always be at least one part
      parts[1] || 0,
      parts[2] || 1,
      parts[3] || 0,
      parts[4] || 0,
      parts[5] || 0,
      parts[6] || 0
    ).getTime();
  }

  private truncateDate(date: Date, size: number) {
    return this.getTimestampFromParts(
      [
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      ].slice(0, size)
    );
  }
}
