import {
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
  Type,
  ViewContainerRef
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OverlayContainerBaseComponent } from './overlay-container-base';
import { OverlayStyle } from './overlay-style';

@Directive({
  selector: '[appOverlayContainer]'
})
export class OverlayContainerDirective implements OnDestroy {
  @Input() set OverlayComponent(
    overlayComponent: Type<OverlayContainerBaseComponent>
  ) {
    this._OverlayComponent = overlayComponent;
  }
  get OverlayComponent(): Type<OverlayContainerBaseComponent> {
    return this._OverlayComponent;
  }

  @Input() set OverlayTemplate(overlayTemplate: TemplateRef<any>) {
    this._OverlayTemplate = overlayTemplate;
  }
  get OverlayTemplate(): TemplateRef<any> {
    return this._OverlayTemplate;
  }

  @Input() AutoPositionStrategy = true;
  @Input() OverlayPosition: 'top' | 'bottom' = 'bottom';
  @Input()
  set activation(activation: 'click' | 'hover' | 'manual') {
    this._activation = activation;
  }
  get activation(): 'click' | 'hover' | 'manual' {
    return this._activation;
  }

  @Output() public openPanel = new EventEmitter<boolean>();

  private _activation: 'click' | 'hover' | 'manual' = 'manual';
  protected _OverlayTemplate!: TemplateRef<any>;
  protected _OverlayComponent!: Type<OverlayContainerBaseComponent>;
  protected portal!: EmbeddedViewRef<any>;
  protected overlayOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  /**
   * manually opens or closes the container
   */
  @Input()
  set open(value: boolean) {
    this._open = value;
    this.openPanel.emit(value);
    if (value === true) {
      this.showOverlay();
    } else {
      this.hideOverlay();
    }
  }
  get open(): boolean {
    return this._open;
  }

  private _open = false;

  @Input()
  disableCloseOnOutsideClick = false;

  private RenderedComponentRef!: ComponentRef<any>;
  constructor(
    protected elementRef: ElementRef,
    protected viewContainerRef: ViewContainerRef,
    protected renderer: Renderer2
  ) {}

  @HostListener('click', ['$event'])
  click(event: MouseEvent) {
    if (this.activation === 'click') {
      event.stopPropagation();
      if (!this.open) {
        this.open = true;
      } else {
        this.open = false;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenHeight(): number {
    return window.innerHeight;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (
      (this.activation === 'click' || this.activation === 'manual') &&
      this.elementRef.nativeElement.contains(event.composedPath()[0]) ===
        false &&
      !this.disableCloseOnOutsideClick
    ) {
      if (this.open) {
        this.open = false;
      }
    }
  }

  @HostListener('mouseenter')
  mouseEnter() {
    if (this.activation === 'hover') {
      this.open = true;
    }
  }

  @HostListener('mouseleave', ['$event'])
  mouseOut($event: MouseEvent) {
    if (this.activation === 'hover') {
      if (
        $event.relatedTarget &&
        !(
          ($event.relatedTarget as HTMLElement).classList.contains(
            'overlay-container'
          ) ||
          ($event.relatedTarget as HTMLElement).classList.contains(
            'tooltip-content'
          )
        )
      ) {
        this.open = false;
      }
    }
  }

  /**
   * Adds base styles for the overlay-container
   */
  private addBaseStyles(elementRef: ElementRef, customStyles?: OverlayStyle[]) {
    elementRef.nativeElement.classList = 'overlay-container';
    if (customStyles !== undefined) {
      customStyles.forEach(element => {
        this.renderer.setStyle(
          elementRef.nativeElement,
          element.property,
          element.value
        );
      });
    } else {
      elementRef.nativeElement.style.position = 'absolute';
      elementRef.nativeElement.style.zIndex = '3000';
      elementRef.nativeElement.style.boxSizing = 'border-box';
      elementRef.nativeElement.style.display = 'flex';
      elementRef.nativeElement.style.width = 'fit-content';
      elementRef.nativeElement.style.height = 'fit-content';
      elementRef.nativeElement.style.visibility = 'hidden';
      elementRef.nativeElement.style.backgroundColor = ' #fff';
    }
  }

  /**
   *  Adds position relative to parent
   */
  private addParentRelativeStyle(elementRef: ElementRef) {
    elementRef.nativeElement.style.position = 'relative';
  }

  /**
   * listening for a close event for the overlay, from the container itself
   */
  private subscribeChildShowOverlay() {
    this.RenderedComponentRef?.instance?.ShowOverlay?.subscribe(
      (value: boolean) => {
        if (value === false) {
          this.open = false;
        }
      }
    );
  }

  /**
   * Renders a component, adds inputs and activation
   */
  public ComponentPortal(
    component: Type<OverlayContainerBaseComponent>,
    data?: any,
    customStyles?: OverlayStyle[]
  ) {
    this.addParentRelativeStyle(this.elementRef);
    const componentRef = this.viewContainerRef.createComponent(component);
    this.addBaseStyles(componentRef.location, customStyles);
    componentRef.instance.activation = this.activation;
    this.RenderedComponentRef = componentRef;
    if (data !== null && data !== undefined) {
      Object.entries(data).forEach(dataArray => {
        this.RenderedComponentRef.instance[dataArray[0]] = dataArray[1];
      });
    }
    this.subscribeChildShowOverlay();
    // if the overlay needs to be a child of the parent
    // this.elementRef.nativeElement.append(componentRef.location.nativeElement);
    this.positionStrategy();

    return componentRef;
  }

  /**
   * Renders a template into the overlay Base container
   */
  public TemplatePortal(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    data?: any,
    customStyles?: OverlayStyle[]
  ) {
    this.addParentRelativeStyle(viewContainerRef.element);
    const overlayContainer = this.viewContainerRef.createComponent(
      OverlayContainerBaseComponent
    );
    this.RenderedComponentRef = overlayContainer;
    this.addBaseStyles(overlayContainer.location, customStyles);

    overlayContainer.instance.activation = this.activation;
    this.subscribeChildShowOverlay();
    viewContainerRef.element.nativeElement.append(
      overlayContainer.location.nativeElement
    );

    const embeddedViewRef =
      overlayContainer.instance.containerRef.createEmbeddedView(templateRef);

    for (const childNode of embeddedViewRef.rootNodes) {
      overlayContainer.location.nativeElement.appendChild(childNode);
    }

    if (data !== null && data !== undefined) {
      Object.entries(data).forEach(dataArray => {
        this.RenderedComponentRef.instance[dataArray[0]] = dataArray[1];
      });
    }
    this.positionStrategy();
    return embeddedViewRef;
  }

  private positionStrategy() {
    const containerChild = this.RenderedComponentRef.location.nativeElement
      .children as HTMLCollection;

    setTimeout(() => {
      const scrHeight = this.getScreenHeight();
      if (
        containerChild.item(0)?.clientHeight !== undefined &&
        this.AutoPositionStrategy
      ) {
        const componentHeight = containerChild.item(0)?.clientHeight as number;
        if (
          scrHeight -
            this.elementRef.nativeElement.getBoundingClientRect().bottom <
          componentHeight
        ) {
          if (
            this.elementRef.nativeElement.getBoundingClientRect().top <
            componentHeight
          ) {
            this.OverlayPosition = 'bottom';
          } else {
            this.OverlayPosition = 'top';
          }
        } else {
          this.OverlayPosition = 'bottom';
        }
      }
      this.Positioning(this.RenderedComponentRef);
      this.RenderedComponentRef.location.nativeElement.style.visibility =
        'visible';
    }, 0);
  }

  protected showOverlay() {
    if (this.OverlayComponent !== undefined && this.OverlayComponent !== null) {
      this.ComponentPortal(this.OverlayComponent);
    } else if (
      this._OverlayTemplate !== undefined &&
      this._OverlayTemplate !== null
    ) {
      this.TemplatePortal(this._OverlayTemplate, this.viewContainerRef);
    }
    this.overlayOpen.next(true);
  }
  protected hideOverlay() {
    this.overlayOpen.next(false);
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
  }

  /**
   * Positions the overlay container top and bottom
   */
  private Positioning(componentRef: ComponentRef<any>) {
    switch (this.OverlayPosition) {
      case 'bottom':
        return this.AlignBottom(componentRef);
      case 'top':
        return this.AlignTop(componentRef);
      default:
        return this.AlignBottom(componentRef);
    }
  }

  private AlignBottom(component: ComponentRef<any>): ComponentRef<any> {
    return component;
  }

  private AlignTop(component: ComponentRef<any>): ComponentRef<any> {
    component.location.nativeElement.style.bottom = `${this.elementRef.nativeElement.offsetHeight}px`;
    return component;
  }

  ngOnDestroy(): void {
    this.RenderedComponentRef?.instance?.ShowOverlay?.unsubscribe();
  }
}
