<div class="shape-datePicker">
  <div class="shape-inputGroup">
    <label [attr.for]="name">
      <span
        *ngIf="label"
        class="shape-datePicker__label shape-inputGroup__label"
        [class.shape-inputGroup__label--mandatory]="required"
      >
        {{ label }}</span
      >
      <div
        #input
        class="shape-inputIcon"
        style="display: flex; align-items: center"
        [class.shape-datePicker__placeholderLabel]="placeholderTopLabel"
        [class.shape-datePicker__placeholderLabel--visible]="
          placeholderTopVisible$ | async
        "
      >
        <input
          type="text"
          autocomplete="off"
          class="shape-datePicker__input shape-inputIcon__input shape-input shape-input--stateful"
          [formControl]="inputControl"
          [class.shape-datePicker__input--calendarSelectionOnly]="disableInput"
          [class.is-invalid]="isInvalid"
          [readOnly]="readonly || disableInput"
          [placeholder]="placeholder"
          [attr.id]="name"
          [maxlength]="inputMaxLength"
          (touchstart)="touchStart($event)"
          (focus)="onFocus()"
        />
        <span
          *ngIf="placeholderTopVisible$ | async"
          class="shape-datePicker__placeholderLabel--label"
        >
          {{ placeholder }}
        </span>
        <div
          class="shape-datePicker__icon"
          [class.shape-inputIcon__icon--disabled]="inputControl.disabled"
          *ngIf="!readonly"
          (click)="togglePicker($event)"
        >
          <p-icon [name]="'calendar'"></p-icon>
        </div>
      </div>
    </label>
  </div>
</div>
<ng-template #calendar>
  <app-calendar-month
    [formControl]="calendarControl"
    [locale]="locale"
    [maxDate]="initialPositionDate"
    (dateSelected)="onDateSelected()"
  ></app-calendar-month>
</ng-template>
