import { Component, HostListener, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-overlay-container-base',
  template: ''
})
export class OverlayContainerBaseComponent {
  public ShowOverlay: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  public activation: 'click' | 'hover' | 'manual' = 'click';
  public containerRef: ViewContainerRef;

  constructor(public vcRef: ViewContainerRef) {
    this.containerRef = vcRef;
  }

  @HostListener('mouseleave', ['$event'])
  mouseOut($event: MouseEvent) {
    if (this.activation === 'hover') {
      this.close();
    }
  }

  public close() {
    this.ShowOverlay.next(false);
  }
}
