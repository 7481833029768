import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { mapLanguageToLocale } from './models/locale.model';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'vehicle-ownership-termination';
  public env = environment;
  constructor(private translateService: TranslocoService) {
    const lang =
      mapLanguageToLocale().language || this.translateService.getDefaultLang();
    this.translateService.setActiveLang(lang);
  }
}
