<div class="shape-calendar" (click)="stopClickPropagation($event)">
  <div class="shape-calendar__header">
    <p-button-pure
      [icon]="'arrow-head-left'"
      (click)="previous()"
    ></p-button-pure>
    <p-text
      [weight]="'semi-bold'"
      (click)="toggleView(calendarCurrentViewEnum.YEAR, $event)"
      class="shape-calendar__headerLabels"
    >
      {{ currentYear }}
    </p-text>
    <p-button-pure [icon]="'arrow-head-right'" (click)="next()"></p-button-pure>
  </div>
  <div class="shape-calendar__picker shape-calendar__picker--month">
    <div class="shape-calendar__itemContainer">
      <label
        *ngFor="let month of monthLabels; let index = index"
        class="shape-calendar__item shape-calendar__item--month"
        [ngClass]="{
          'shape-calendar__item--active': index === currentMonth,
          'shape-calendar__item--outsideInterval': !(
            validInterval | containsDate: currentYear : index
          )
        }"
        (click)="selectElement(index)"
      >
        {{ month }}
      </label>
    </div>
  </div>
</div>
