import { Component, forwardRef, inject, signal } from '@angular/core';
import { userLocale } from '../../models/locale.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, Subscription, mergeMap } from 'rxjs';
import { OwnershipTerminationService } from '../../services/ownership-termination.service';

@Component({
  selector: 'app-vehicle-ownership-status',
  templateUrl: './vehicle-ownership-status.component.html',
  styleUrls: ['./vehicle-ownership-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VehicleOwnershipStatusComponent),
      multi: true
    }
  ]
})
export class VehicleOwnershipStatusComponent {
  private route = inject(ActivatedRoute);
  private ownershipTerminationService = inject(OwnershipTerminationService);
  private subscription = new Subscription();
  private selectedDate!: Date | null;
  public locale = userLocale;
  public isInvalid = signal(false);

  public bpId$ = this.route.queryParams.pipe(
    map(queryParams => queryParams['BPID'])
  );
  public vin$ = this.route.queryParams.pipe(
    map(queryParams => queryParams['VIN'])
  );
  public model$ = this.route.queryParams.pipe(
    map(queryParams => queryParams['MODELDESC'])
  );
  public id$ = this.route.queryParams.pipe(
    map(queryParams => queryParams['ID'])
  );

  terminateOwnership() {
    if (!!this.selectedDate) {
      this.subscription.add(
        this.route.queryParams
          .pipe(
            mergeMap( queryParams =>
              this.ownershipTerminationService.terminate({
                id: queryParams['ID'],
                system: queryParams['SYSTEM'],
                terminationDate: this.selectedDate!,
                date: new Date()
              })
            )
          )
          .subscribe()
      );
    } else {
      this.isInvalid.set(true);
    }
  }

  terminationDate(event: Date | null) {
    this.selectedDate = event;
    this.onFocus();
  }

  onFocus() {
    !!this.selectedDate ? this.isInvalid.set(false) : this.isInvalid.set(true);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
