import { Component, Input, OnInit } from '@angular/core';
import {
  getLongMonthLabel,
  getShortDayLabel,
  getShortMonthLabel,
  isValidDate
} from '../../utils/date.util';
import { CalendarCurrentView } from '../model/calendar-current-view.enum';
import { DayPickerElement } from '../model/day-picker-element.interface';

@Component({
  template: ''
})
export abstract class CalendarBaseComponent implements OnInit {
  @Input() public locale = 'de-DE';
  @Input() public yearOffset = 8;
  /**
   * Sets the date (month + year) to be shown when opening an unselected calendar.
   */
  @Input() public initialPositionDate = new Date();
  public dayPickerRows: DayPickerElement[][] = [];

  public currentYear!: number;
  public currentMonth!: number;

  public dayLabels: string[] = [];
  public monthLabels: string[] = [];
  public yearLabels: number[] = [];
  public currentMonthLabel!: string;

  public calendarCurrentViewEnum = CalendarCurrentView;
  public currentView: CalendarCurrentView = CalendarCurrentView.DAY;

  public selectedYear?: number;
  public selectedMonth?: number;
  public selectedDay?: number;

  protected yearStart!: number;
  protected _date!: Date;

  protected abstract updateDayPicker(): void;

  protected movePage(direction: number) {
    if (this.currentView === CalendarCurrentView.DAY) {
      this.selectMonth(this.currentMonth + 1 * direction);
    } else if (this.currentView === CalendarCurrentView.YEAR) {
      this.yearStart += 16 * direction;
      this.updateYearPicker();
    } else {
      this.yearStart += 1 * direction;
      this.currentYear += 1 * direction;
    }
  }

  public ngOnInit() {
    this.getPosition();
    this.updateDayPicker();
    this.updateYearPicker();
    this.getLabels();
  }

  public previous() {
    this.movePage(-1);
  }

  public next() {
    this.movePage(1);
  }

  public toggleView(view: CalendarCurrentView, event: MouseEvent) {
    event.stopPropagation();
    if (this.currentView === view) {
      this.currentView = CalendarCurrentView.DAY;
    } else {
      if (view === CalendarCurrentView.YEAR) {
        this.yearStart = this.currentYear - this.yearOffset;
        this.updateYearPicker();
      }

      this.currentView = view;
    }
  }

  public selectYear(year: number) {
    this.currentYear = year;
    this.updateDayPicker();
  }

  public selectMonth(month: number) {
    const date = new Date(this.currentYear, month);
    this.currentYear = date.getFullYear();
    this.currentMonth = date.getMonth();
    this.currentMonthLabel = getLongMonthLabel(date, this.locale);
    this.updateDayPicker();
  }

  protected updateYearPicker() {
    this.yearLabels = [];
    for (let idx = 0; idx < 16; idx++) {
      this.yearLabels.push(this.yearStart + idx);
    }
  }

  protected getPosition() {
    let currentDate: Date;

    if (isValidDate(this._date)) {
      this.selectedYear = this._date.getFullYear();
      this.selectedMonth = this._date.getMonth();
      this.selectedDay = this._date.getDate();
      currentDate = this._date;
    } else {
      // clear any previously selected date if the date is invalid
      this.selectedYear = undefined;
      this.selectedMonth = undefined;
      this.selectedDay = undefined;
      currentDate = new Date(this.initialPositionDate);
    }

    this.currentYear = currentDate.getFullYear();
    this.currentMonth = currentDate.getMonth();
  }

  protected getLabels() {
    for (const day of this.dayPickerRows[1]) {
      const date = new Date(this.currentYear, this.currentMonth, day.day);
      this.dayLabels.push(getShortDayLabel(date, this.locale));
    }

    for (let month = 0; month < 12; month++) {
      const date = new Date(this.currentYear, month);
      this.monthLabels.push(getShortMonthLabel(date, this.locale));
    }

    const currentMonthDate = new Date(this.currentYear, this.currentMonth);
    this.currentMonthLabel = getLongMonthLabel(currentMonthDate, this.locale);
  }

  protected areEqualTruncating(
    a: Date | null | undefined,
    b: Date | null | undefined
  ) {
    if (!a && !b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    return (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate()
    );
  }
}
