import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  getFirstWeekday,
  getLongMonthLabel,
  getNumberOfDays
} from '../../utils/date.util';
import { CalendarBaseComponent } from '../calendar/calendar-base';
import { CalendarCurrentView } from '../model/calendar-current-view.enum';
import { DayPickerElement } from '../model/day-picker-element.interface';
import { RangePeriod } from '../model/range-period.interface';

@Component({
  selector: 'app-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: [
    './calendar-month.component.scss',
    '../styles/calendar.scss',
    '../styles/headline.scss',
    '../styles/clickable.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CalendarMonthComponent,
      multi: true
    }
  ]
})
export class CalendarMonthComponent
  extends CalendarBaseComponent
  implements OnInit, OnDestroy
{
  @Input()
  public set minDate(value: Date | null) {
    this.validInterval.startDate = value;
  }
  public get minDate() {
    return this.validInterval.startDate;
  }

  @Input()
  public set maxDate(value: Date | null) {
    this.validInterval.endDate = value;
  }
  public get maxDate() {
    return this.validInterval.endDate;
  }

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  @Output()
  public dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Output()
  public dateSelected: EventEmitter<Date> = new EventEmitter<Date>();

  public calendarCurrentView: CalendarCurrentView = CalendarCurrentView.MONTH;

  public dateControl: FormControl = new FormControl();
  public validInterval: RangePeriod = { startDate: null, endDate: null };
  private subscriptions = new Subscription();

  public override ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public override movePage(direction: number) {
    this.yearStart += direction;
    this.currentYear += direction;
  }

  public selectElement(month: number) {
    this._date = new Date(this.currentYear, month);
    this.selectedYear = this._date.getFullYear();
    this.selectedMonth = this._date.getMonth();
    this.dateControl.setValue(this._date);
    this.dateChange.emit(this._date);
    this.dateSelected.emit(this._date);
  }

  public isSelected(element: DayPickerElement) {
    // creating new Date to handle negative month (last year)
    const elementDate = new Date(this.currentYear, element.month);
    return (
      this.selectedYear === elementDate.getFullYear() &&
      this.selectedMonth === elementDate.getMonth()
    );
  }

  protected updateDayPicker() {
    const currentDate = new Date(this.currentYear, this.currentMonth);
    const firstWeekday = (getFirstWeekday(currentDate).getDay() + 6) % 7; // Parse to Monday
    const numberOfDays = getNumberOfDays(currentDate);
    let dayIndex = 0;
    this.dayPickerRows = [];

    for (let rowIndex = 0; rowIndex < 6; rowIndex++) {
      const row = [];
      for (let columnIndex = 0; columnIndex < 7; columnIndex++) {
        let element: DayPickerElement;

        if (dayIndex < firstWeekday) {
          // elements before current month
          element = {
            month: this.currentMonth - 1
          };
        } else if (dayIndex - firstWeekday < numberOfDays) {
          // elements in current month
          element = {
            month: this.currentMonth
          };
        } else {
          // elements in next month
          element = {
            month: this.currentMonth + 1
          };
        }

        row.push(element);
        dayIndex++;
      }

      this.dayPickerRows.push(row);
    }
  }

  registerOnChange(fn: any): void {
    this.subscriptions.add(this.dateControl.valueChanges.subscribe(fn));
  }

  registerOnTouched(_fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateControl.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.dateControl.enable({ onlySelf: true, emitEvent: false });
    }
  }

  writeValue(newDate: Date | null | undefined): void {
    const previousDate = this._date;
    this.dateControl.setValue(newDate, { emitEvent: false, onlySelf: true });
    this._date = newDate ? newDate : new Date(NaN);

    // perf: do not update the view if {year, month} are the same
    if (!this.areEqualTruncating(previousDate, newDate)) {
      this.getPosition();
      this.updateDayPicker();
      // Make sure that the month label always shows the label corresponding to
      // the current month displayed. Since `this._date` can be invalid (NaN),
      // use a reference date created with `this.currentMonth` instead.
      this.currentMonthLabel = getLongMonthLabel(
        new Date(this.currentYear, this.currentMonth),
        this.locale
      );

      this.cd.markForCheck();
    }
  }

  stopClickPropagation(event: any) {
    event.stopPropagation();
  }
}
