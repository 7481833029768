export enum Locale {
  DE = 'de', // German
  EN = 'en', // English
  ES = 'es', // Spanish
  FR = 'fr', // French
  IT = 'it', // Italian
  JA = 'ja', // Japanese
  KO = 'ko', // Korean
  NL = 'nl', // Dutch
  PL = 'pl', // Polish
  PT = 'pt', // Portuguese
  RU = 'ru', // Russian
  TH = 'th', // Thai
  ZH = 'zh', // Chinese - China
  ZF = 'zf' // Chinese - Taiwan
}

export function getLanguageRegion( language?: string ): string {

  if(language && language.includes('-')){
    return language;
  }

  if (language && defaultRegions[language]) {
    return defaultRegions[language];
  }

  return 'en-US';
}

//Standard Region per Language for Fallback
export const defaultRegions: Record<string, string> = {
  af: "af-ZA", sq: "sq-AL", am: "am-ET", ar: "ar-SA", hy: "hy-AM",
  az: "az-AZ", eu: "eu-ES", be: "be-BY", bn: "bn-BD", bs: "bs-BA",
  bg: "bg-BG", ca: "ca-ES", ceb: "ceb-PH", zh: "zh-CN", co: "co-FR",
  hr: "hr-HR", cs: "cs-CZ", da: "da-DK", nl: "nl-NL", en: "en-US",
  eo: "eo-EO", et: "et-EE", fil: "fil-PH", fi: "fi-FI", fr: "fr-FR",
  fy: "fy-NL", gl: "gl-ES", ka: "ka-GE", de: "de-DE", el: "el-GR",
  gu: "gu-IN", ha: "ha-NG", he: "he-IL", hi: "hi-IN", hu: "hu-HU",
  is: "is-IS", id: "id-ID", ga: "ga-IE", it: "it-IT", ja: "ja-JP",
  jv: "jv-ID", kn: "kn-IN", kk: "kk-KZ", km: "km-KH", ko: "ko-KR",
  ku: "ku-TR", ky: "ky-KG", lo: "lo-LA", lv: "lv-LV", lt: "lt-LT",
  lb: "lb-LU", mk: "mk-MK", mg: "mg-MG", ms: "ms-MY", ml: "ml-IN",
  mt: "mt-MT", mi: "mi-NZ", mr: "mr-IN", mn: "mn-MN", my: "my-MM",
  ne: "ne-NP", nb: "nb-NO", nn: "nn-NO", or: "or-IN", ps: "ps-AF",
  fa: "fa-IR", pl: "pl-PL", pt: "pt-BR", pa: "pa-IN", ro: "ro-RO",
  ru: "ru-RU", gd: "gd-GB", sr: "sr-RS", si: "si-LK", sk: "sk-SK",
  sl: "sl-SI", so: "so-SO", es: "es-ES", su: "su-ID", sw: "sw-KE",
  sv: "sv-SE", ta: "ta-IN", te: "te-IN", th: "th-TH", tr: "tr-TR",
  uk: "uk-UA", ur: "ur-PK", uz: "uz-UZ", vi: "vi-VN", cy: "cy-GB",
  xh: "xh-ZA", yi: "yi-001", yo: "yo-NG", zu: "zu-ZA"
};


export const validLocales = Object.values(Locale);

export const userLocale =
getLanguageRegion(
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language);

interface UserLocale {
  language: string;
  region?: string;
}

export const mapLanguageToLocale = () => {
  const [lang, region] = userLocale.split('-');
  return {
    language: lang,
    region: region || undefined
  } as UserLocale;
};
