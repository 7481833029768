<header>
  <phn-header [env]="env.env" [locale]="env.locale" mode="hero"></phn-header>
</header>
<body>
  <router-outlet />
</body>
<footer>
  <pnav-footer [env]="env.env" [locale]="env.locale" />
</footer>
<p-toast></p-toast>
