export enum Locale {
  DE = 'de', // German
  EN = 'en', // English
  ES = 'es', // Spanish
  FR = 'fr', // French
  IT = 'it', // Italian
  JA = 'ja', // Japanese
  KO = 'ko', // Korean
  NL = 'nl', // Dutch
  PL = 'pl', // Polish
  PT = 'pt', // Portuguese
  RU = 'ru', // Russian
  TH = 'th', // Thai
  ZH = 'zh', // Chinese - China
  ZF = 'zf' // Chinese - Taiwan
}

export const validLocales = Object.values(Locale);

export const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

interface UserLocale {
  language: string;
  region?: string;
}

export const mapLanguageToLocale = () => {
  const [lang, region] = userLocale.split('-');
  return {
    language: lang,
    region: region || undefined
  } as UserLocale;
};
