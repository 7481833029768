import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import {
  defineCustomElements as definePorscheNavigation
} from '@pcom-navigate/header/loader';
import { defineCustomElements as definePorscheFooter } from '@pcom-navigate/footer/loader';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

definePorscheNavigation(window);
definePorscheFooter(window);
