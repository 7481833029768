import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TranslocoRootModule } from './transloco-root.module';
import { VehicleOwnershipStatusComponent } from './components/vehicle-ownership-status/vehicle-ownership-status.component';
import { DatePickerModule } from './date-picker/date-picker.module';
import { UpdateSuccessfulComponent } from './components/update-successful/update-successful.component';
import { OwnershipTerminationService } from './services/ownership-termination.service';

@NgModule({
  declarations: [
    AppComponent,
    VehicleOwnershipStatusComponent,
    UpdateSuccessfulComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PorscheDesignSystemModule,
    TranslocoRootModule,
    HttpClientModule,
    DatePickerModule
  ],
  providers: [OwnershipTerminationService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
