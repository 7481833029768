export const isValidDateFormat = (value: string) => {
  return /^\d{2}([./-])\d{2}\1\d{4}$/.test(value);
};

export const getFirstWeekday = (date: Date): Date => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstWeekday = new Date(year, month, 1);
  return firstWeekday;
};

export const getNumberOfDays = (date: Date): number => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (month === 4 || month === 6 || month === 9 || month === 11) {
    return 30;
  } else if (month !== 2) {
    return 31;
  } else if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
    return 29;
  } else {
    return 28;
  }
};

export const getShortDayLabel = (date: Date, locale?: string): string => {
  return date.toLocaleDateString(locale, { weekday: 'short' });
};

export const getShortMonthLabel = (date: Date, locale?: string): string => {
  return date.toLocaleString(locale, { month: 'short' });
};

export const getLongMonthLabel = (date: Date, locale?: string): string => {
  return date.toLocaleDateString(locale, { month: 'long' });
};

export const getShortDateString = (
  date: Date | null,
  locale?: string,
  options?: {}
): string => {
  return date
    ? date.toLocaleDateString(
        locale,
        options ? options : { year: 'numeric', month: '2-digit' }
      )
    : '';
};

export const getShortDatetimeString = (
  date: Date | null,
  locale?: string
): string => {
  return date
    ? date.toLocaleString(locale, {
        formatMatcher: 'basic',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      })
    : '';
};

export const isValidDate = (date: any): date is Date => {
  return (
    Object.prototype.toString.call(date) === '[object Date]' &&
    date.toString() !== 'Invalid Date'
  );
};

const NUMBER_REGEXP = /^\d+$/;

const parseFromReference = (
  dateString: string,
  referenceString: string,
  referenceParts: string[]
): Date | null => {
  if (dateString.length !== referenceString.length) {
    return null;
  }

  const parts = referenceParts.map(referencePart => {
    const index = referenceString.indexOf(referencePart);
    const part = dateString.substring(index, index + referencePart.length);
    return NUMBER_REGEXP.test(part) ? parseInt(part, 10) : NaN;
  });
  if (parts.some(part => isNaN(part))) {
    return null;
  }

  const readPart = (index: number, fallback = 0) => {
    return parts.length > index ? parts[index] : fallback;
  };

  const year = readPart(0);
  const month = readPart(1, 1) - 1; // make it 0-indexed

  // keeping each condition in a separate clause for easier debugging
  if (year < 0 || year > new Date().getFullYear()) {
    return null;
  }
  if (month < 0 || month > 11) {
    return null;
  }
  return new Date(year, month);
};

export const parseShortDateString = (
  dateString: string,
  locale: string
): Date | null => {
  return parseFromReference(
    dateString,
    getShortDateString(new Date(2000, 2), locale),
    ['2000', '03']
  );
};
