import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { CalendarMonthComponent } from './calendar-month/calendar-month.component';
import { ContainsDatePipe } from './contains-date/contains-date.pipe';
import { DatePickerComponent } from './date-picker/date-picker.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HammerModule,
    PorscheDesignSystemModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe],
  declarations: [DatePickerComponent, CalendarMonthComponent, ContainsDatePipe],
  exports: [DatePickerComponent]
})
export class DatePickerModule {}
