import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VehicleOwnershipStatusComponent } from './components/vehicle-ownership-status/vehicle-ownership-status.component';
import { UpdateSuccessfulComponent } from './components/update-successful/update-successful.component';

const routes: Routes = [
  {
    path: '',
    component: VehicleOwnershipStatusComponent
  },
  {
    path: 'success',
    component: UpdateSuccessfulComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
