import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { OverlayContainerDirective } from '../../overlay-container/overlay-container.directive';
import {
  getShortDateString,
  parseShortDateString
} from '../../utils/date.util';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: [
    '../styles/datePicker.scss',
    '../styles/inputGroup.scss',
    '../styles/inputIcon.scss',
    '../styles/input.scss',
    '../styles/clickable.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent
  extends OverlayContainerDirective
  implements OnInit, OnDestroy
{
  /**
   * Set the picker's selected date.
   */
  @Input()
  public set date(value: Date | null) {
    if (value !== this.calendarControl.value) {
      this.writeValue(value);
    }
  }

  /**
   * Set the picker to disabled.
   */
  @Input()
  public set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    protected changeDetectorRef: ChangeDetectorRef,
    protected override renderer: Renderer2,
    protected override elementRef: ElementRef
  ) {
    super(elementRef, viewContainerRef, renderer);
    this.activation = 'manual';
  }
  @ViewChild('input', { static: true }) inputRef!: ElementRef;

  @ViewChild('calendar', { static: true }) calendarRef!: TemplateRef<any>;

  /**
   * Set the picker's name.
   */
  @Input() public name = 'date-picker';
  /**
   * Set the label of the picker, if needed.
   */
  @Input() public label!: string;
  /**
   * Set the picker's placeholder.
   */
  @Input() public placeholder!: string | null;

  /**
   * Show placeholder at the top of the input together with the date
   */
  @Input() public placeholderTopLabel = false;

  /**
   * Sets the locale to display date format and labels according to language.
   * If dateFormat Input is set it will override the default format of the locale.
   */
  @Input() public locale = 'de-DE';

  /**
   * Boolean property to show if the picker is readonly or Not.
   */
  @Input() public readonly = false;
  /**
   * Boolean property to show if the picker is mandatory or Not.
   */
  @Input() public required = false;
  /**
   * Set the boolean to show if the picker has Errors or Not.
   */
  @Input() public isInvalid = false;
  /**
   * Sets the debounce time in milliseconds for the ModelChange.
   */
  @Input() public inputDebounce = 300;
  /**
   * Sets the date to be only selected via calendar and no custom text input
   */
  @Input() public disableInput = false;
  /**
   * Sets the date the calendar will open by default when there
   * are no selected dates.
   */
  @Input() public initialPositionDate = new Date();
  /**
   * Sets the text input maxlength attribute.
   * A null value means there is not maxlength on the input.
   */
  public inputMaxLength: number | null = 10;

  /**
   * Whether to hide the calendar immediately after a date is selected.
   */
  private hideCalendarOnDateSelected = true;

  /**
   * Sets the format for the locale date. Object needs to be a valid option for the
   * [Date.prototype.toLocaleDateString]
   * (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString)
   * method
   */
  @Input() public dateFormat = {
    year: 'numeric',
    month: '2-digit'
  };

  @Input()
  get autoPositioning() {
    return this.AutoPositionStrategy;
  }
  set autoPositioning(value: boolean) {
    this.AutoPositionStrategy = value;
  }

  /**
   * Emits when date changes
   */
  @Output()
  public changeDate: EventEmitter<Date | null> =
    new EventEmitter<Date | null>();

  /**
   * Emits when the date input is focused or the calendar is opened
   */
  @Output() public touched: EventEmitter<any> = new EventEmitter<any>();

  private onChange: any;

  public inputControl = new FormControl();

  public calendarControl = new FormControl();

  private subscription = new Subscription();

  public topLabel$ = new BehaviorSubject<boolean>(false);

  public placeholderTopVisible$: Observable<boolean> = this.topLabel$.pipe(
    map(topLabel => topLabel && this.placeholderTopLabel)
  );

  private formatDate = (
    date: Date | null,
    locale: string,
    options?: {}
  ): string | null => {
    return getShortDateString(date, locale, options);
  };

  public ngOnInit() {
    this.subscription.add(
      this.calendarControl.valueChanges.subscribe((value: Date) => {
        this.inputControl.setValue(
          this.formatDate(value, this.locale, this.dateFormat),
          { emitEvent: false }
        );
        this.topLabel$.next(!!value);
        this.emitChange(value);
      })
    );

    this.subscription.add(
      this.inputControl.valueChanges
        .pipe(debounceTime(this.inputDebounce))
        .subscribe((value: string) => {
          const date = parseShortDateString(value, this.locale);
          this.calendarControl.setValue(date, { emitEvent: false });
          this.topLabel$.next(!!date);
          this.emitChange(date);
          this.changeDetectorRef.markForCheck();
        })
    );
    if (this.placeholder === undefined) {
      this.placeholder = this.formatDate(
        new Date(),
        this.locale,
        this.dateFormat
      );
    }

    // update the value displayed in the input field since it may have been
    // initialized before all properties in the component were initialized
    this.inputControl.setValue(
      this.formatDate(this.calendarControl.value, this.locale, this.dateFormat),
      {
        onlySelf: true,
        emitEvent: false
      }
    );
  }

  public openPicker() {
    if (!this.readonly) {
      this.portal = this.TemplatePortal(
        this.calendarRef,
        this.viewContainerRef
      );
      this.open = true;
    }
  }

  public touchStart(event: Event) {
    event.preventDefault();
    if (!this.open) {
      this.openPicker();
    }

    this.touched.emit();
  }

  public togglePicker(event: Event) {
    event.preventDefault();
    if (this.open) {
      this.open = false;
    } else if (!this.inputControl.disabled) {
      this.openPicker();
    }

    this.touched.emit();
  }

  public onFocus(): void {
    if (!this.open) {
      this.openPicker();
    }

    this.touched.emit();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(_fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputControl.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.inputControl.enable({ onlySelf: true, emitEvent: false });
    }

    // mark as dirty to update bindings which depend on
    // this control's disabled status, like the calendar icon's class
    this.changeDetectorRef.markForCheck();
  }

  writeValue(value: Date | null): void {
    this.inputControl.setValue(this.formatDate(value, this.locale), {
      onlySelf: true,
      emitEvent: false
    });
    this.calendarControl.setValue(value, { onlySelf: true, emitEvent: false });
    this.topLabel$.next(!!value);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  emitChange(value: Date | null) {
    if (this.onChange) {
      this.onChange(value);
    }
    this.changeDate.emit(value);
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    /* tslint:disable-next-line:deprecation */
    // if (event.keyCode === TAB) {
    //   this.open = false;
    //   this.emitChange(this.calendarControl.value);
    // }
  }

  public onDateSelected() {
    if (this.hideCalendarOnDateSelected) {
      this.open = false;
    }
  }
}
