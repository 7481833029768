import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { EMPTY, catchError, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ToastManager } from '@porsche-design-system/components-angular';

@Injectable({
  providedIn: 'root'
})
export class OwnershipTerminationService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private toast: ToastManager
  ) {}

  private transformParamsForPostBody = (params: {
    id: string;
    system: string;
    terminationDate: Date;
    date: Date;
  }) => ({
    id: params.id,
    system: params.system,
    terminationDate: params.terminationDate.toJSON(),
    date: params.date.toJSON()
  });

  public terminate(params: { id: string; system: string; terminationDate: Date; date: Date }) {
    return this.http
      .post(environment.lambdaEndpoint, this.transformParamsForPostBody(params))
      .pipe(
        switchMap(() => this.router.navigate(['success'])),
        catchError(err => {
          this.toast.addMessage({
            text: err.statusText,
            state: 'neutral'
          });
          return EMPTY;
        })
      );
  }
}
